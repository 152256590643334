export default {
  props: {
    value: Array,
    cardTitle: String,
    attribute: String
  },
  data () {
    return {
      chatLogItem: {
        text: ''
      }
    }
  },
  computed: {
    userName () {
      return this.$store.getters['auth/getName']
    }
  },
  methods: {
    addChatLogItem (attribute) {
      if (!this.value) {
        this.$set(this.value, attribute, [])
      }
      this.value.unshift({
        date: (new Date()).toLocaleString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          weekday: 'short',
          hour: '2-digit',
          minute: '2-digit'
        }),
        text: this.chatLogItem.text,
        user: this.userName
      })
      this.chatLogItem.text = null
    }
  }
}
