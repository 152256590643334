<template>
  <v-card
    class="mt-1 pa-1"
    elevation="0"
    min-height="200"
    outlined
  >
    <v-card-title>{{ cardTitle }}</v-card-title>
    <div
      class="scrollIfSoBig"
      v-if="value"
    >
      <div
        class="pa-1 ma-0"
        v-for="(item, index) in value"
        :key="index"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div
              v-on="on"
              class="d-flex flex-column bubble"
            >
              <span class="caption d-flex justify-end">{{ item.date }}</span>
              <span>{{ item.text }}</span>
            </div>
          </template>
          <span>{{ item.user }}</span>
          <br>
          <span>{{ item.date }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="d-flex">
      <v-col
        class="pa-1 ma-0"
        cols="11"
      >
        <v-textarea
          v-model="chatLogItem.text"
          outlined
          filled
          label="Начните писать текст"
          rows="1"
        ></v-textarea>
      </v-col>
      <v-col cols="1">
        <v-btn
          :disabled="!chatLogItem.text"
          dark
          x-large
          icon
          @click="addChatLogItem(attribute)"
        >
          <v-icon
            color="indigo">
            mdi-send
          </v-icon>
        </v-btn>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import chatLog from '@/components/mixins/chatLog'

export default {
  mixins: [chatLog],
  name: 'ChatLog'
}
</script>

<style scoped>
.bubble {
  width: 300px;
  height: auto;
  padding: 15px 20px;
  background: #90CAF9;
  border-radius: 10px;
}
.scrollIfSoBig {
  max-height: 35vh;
  overflow: auto;
}
</style>
