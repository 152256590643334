<template>
  <v-card>
    <v-card-text class="pt-0">
      <div class="d-flex justify-end mb-3">
        <v-btn
          @click="$emit('close')"
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="d-flex justify-space-around">
        <v-text-field
          v-model="localCompilation.name"
          class="pr-2"
          outlined
          dense
          label="Название вкладки"
        ></v-text-field>
        <v-select
          v-model="localCompilation.type"
          :items="compilationType"
          label="Тип вкладки"
          item-value="value"
          item-text="label"
          class="pt-0"
          outlined
          dense
        >
        </v-select>
        <v-text-field
          v-model="clientName"
          readonly
          class="pl-2 mr-3"
          label="Вкладка для клиента"
          outlined
          dense
        ></v-text-field>
      </div>
      <Filters
        v-model="localCompilation.compilation"
      />
    </v-card-text>
  </v-card>
</template>

<script>

import Filters from '@/components/views/admin/advert/Common/Filters'
import formatters from '@/components/mixins/formatters'
import { compilationTypes } from '@/config'

export default {
  mixins: [formatters],
  name: 'Compilation',
  components: { Filters },
  props: {
    item: Object
  },
  data () {
    return {
      localCompilation: this.item
    }
  },
  computed: {
    clientName () {
      return this.formatValue('users.clients', this.localCompilation.client_id)
    },
    compilationType () {
      return compilationTypes
    }
  },
  watch: {
    item: {
      handler () {
        this.localCompilation = this.item
      },
      deep: true
    }
  }
}

</script>

<style scoped>

</style>
