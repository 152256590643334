import store from '@/store'

class Client {
  constructor () {
    this.id = null
    this.username = null
    this.email = null
    this.birth_date = null
    this.login = ''
    this.parent_id = null
    this.description = null
    this.userPhones = []
    this.avatar = null
    this.address = null
    this.customPermissions = []
    this.compilations = []
    this.client_history = []
    this.role = 'customer'
    this.whatsapp = null
    this.search_reason = []
    this.deal_step = null
    this.success_rate = null
    this.impression = null
    this.payment_method = null
    this.client_status = null
    this.delay_until = null
    this.delay_comment = null
    this.escort_agent = null
    this.usersAdvert = null

    let profile = store.getters['auth/getProfile']
    if (profile && profile.role === 'agent') {
      this.parent_id = profile.id
    }

    let clientStatusItems = store.getters['serviceData/optionsList']('user.client_status', false)
    if (clientStatusItems) {
      this.client_status = clientStatusItems[0].id
    }
  }
}

export default Client
