<template>
  <div>
    <div
      class="text-center d-flex justify-center align-center"
      style="height: 80vh;"
      v-if="isLoading"
    >
      <div>
        <v-progress-circular
          class="text-center"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <template v-if="!isLoading">
      <v-container
        fluid
        class="mb-10"
      >
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="detail.username"
              outlined
              dense
              :label="$t('user.username')"
            ></v-text-field>
            <v-text-field
              v-model="detail.email"
              outlined
              dense
              label="Почта"
            ></v-text-field>
            <BasePhone
              :phone-info="detail.userPhones"
              :item-id="detail.id"
              :attribute-to-save="'userPhones'"
              :category-name="componentCategory"
              @input="handleInput($event)"
              @detail="$emit('detail', $event)"
            >
              <template #btn>
                <RelatedAdverts
                  :phones="detail.userPhones"
                  @detail="$emit('detail', $event)"
                >
                </RelatedAdverts>
                <RelatedClients
                  :phones="detail.userPhones"
                  :item-id="detail.id"
                  :client-category="'1793'"
                  @detail="$emit('detail', $event)"
                >
                </RelatedClients>
                <RelatedPhones
                  :phones="detail.userPhones"
                  @detail="$emit('detail', $event)"
                />
              </template>
            </BasePhone>
          </v-col>
          <v-col cols="9">
            <ClientSelects
              v-model="detail.parent_id"
              :escort-agent.sync="detail.escort_agent"
              :success-rate.sync="detail.success_rate"
              :impression.sync="detail.impression"
              :deal-step.sync="detail.deal_step"
              :created-at="detail.created_at"
              :updated-at="detail.updated_at"
              :delay-until="detail.delay_until"
              :can-edit="canEdit"
            />
            <ClientsData
              :whatsapp.sync="detail.whatsapp"
              :description.sync="detail.description"
              :address.sync="detail.address"
              :search-reason.sync="detail.search_reason"
              :birth-date.sync="detail.birth_date"
              :payment-method.sync="detail.payment_method"
              :delay-until.sync="detail.delay_until"
              :delay-comment.sync="detail.delay_comment"
            />
            <ClientWorkspace
              :compilations.sync="detail.compilations"
              :client-history.sync="detail.client_history"
              :search-reason.sync="detail.search_reason"
              :id="detail.id"
            />
          </v-col>
        </v-row>
      </v-container>
      <BaseButtonBar class="pt-4">
        <template #default>
          <BaseBtn
            color="primary"
            icon="mdi-content-save"
            class="mr-2"
            small
            :loading="isSavingData"
            title="Сохранить"
            :disabled="canEdit"
            @click="saveWithUpdate"
          />
          <BaseBtn
            color="light-green white--text mr-2"
            dark
            icon="mdi-content-save"
            class="mr-2"
            small
            :loading="isSavingData"
            title="Сохранить как..."
            @click="saveAs = !saveAs"
            :disabled="canEdit"
          />
          <SaveAsDialog
            v-model="saveAs"
            :item-status.sync="detail.client_status"
            :items="clientStatusItems"
            :text="'Статус клиента'"
            @saveAs="saveDetail"
          />
          <BaseBtn
            color="error"
            icon="mdi-delete"
            class="mr-2"
            small
            :loading="isSavingData"
            title="Удалить"
            @click="confirmDelete = !confirmDelete"
            :disabled="!canDeleteClient"
          />
          <DeleteDialog
            @delete="deleteDetail"
            v-model="confirmDelete"
            :text="'Действительно хотите удалить клиента?'"
          />
        </template>
        <template #custom>
          <AttributeHistory
            :attribute.sync="detail.escort_agent"
          />
        </template>
      </BaseButtonBar>
    </template>
  </div>
</template>

<script>
import detail from '@/components/mixins/detail'
import { email, minLength, required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import ClientsData from '@/components/views/admin/clients/Detail/ClientsData'
import ClientSelects from '@/components/views/admin/clients/Detail/ClientSelects'
import ClientWorkspace from '@/components/views/admin/clients/Detail/ClientWorkspace'
import Client from '@/components/structures/client'
import BaseButtonBar from '@/components/views/admin/common/BaseButtonBar'
import BaseBtn from '@/components/views/admin/common/BaseButtonBar/BaseBtn'
import SaveAsDialog from '@/components/views/admin/common/BaseButtonBar/SaveAsDialog'
import DeleteDialog from '@/components/views/admin/common/BaseButtonBar/DeleteDialog'
import BasePhone from '@/components/views/admin/common/BasePhone'
import RelatedAdverts from '@/components/views/admin/common/BasePhone/RelatedAdverts'
import RelatedClients from '@/components/views/admin/common/BasePhone/RelatedClients'
import AttributeHistory from '@/components/views/admin/advert/Detail/ButtonBar/AttributeHistory'
import RelatedPhones from '@/components/views/admin/common/RelatedPhones'

export default {
  mixins: [detail, validationErrors],
  components: {
    RelatedAdverts,
    RelatedClients,
    ClientWorkspace,
    ClientSelects,
    ClientsData,
    BaseButtonBar,
    BaseBtn,
    SaveAsDialog,
    DeleteDialog,
    BasePhone,
    AttributeHistory,
    RelatedPhones
  },
  name: 'Details',
  data () {
    return {
      confirmDelete: false,
      saveAs: false,
      componentCategory: 'client',
      forceFillDetail: true
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  validations: {
    detail: {
      email: {
        email,
        isUnique (value) {
          let result = true
          if (this.$v.detail.email.$dirty && value) {
            result = this.$store.dispatch('auth/isUnique', {
              data: {
                email: value,
                id: this.detail.id
              }
            })
          }
          return result
        }
      },
      userPhones: {
        required,
        $each:
          {
            phone: {
              phone: {
                required,
                minLength:
                  minLength(11)
              }
            }
          }
      }
    }
  },
  computed: {
    detailStructure () {
      return new Client()
    },
    clientStatusItems () {
      return this.$store.getters['serviceData/optionsList']('user.client_status', false)
    },
    canEdit () {
      let result = true
      let profile = this.$store.getters['auth/getProfile']
      if (this.detail.parent_id === null || (profile && (profile.role === 'admin' || profile.id === this.detail.parent_id))) {
        result = false
      }
      return result
    },
    canDeleteClient () {
      let result = false
      let profile = this.$store.getters['auth/getProfile']
      if (profile && (profile.role === 'admin' || (this.$can('agent', 'removeClients') && (profile.id === this.detail.parent_id || !this.detail.parent_id)))) {
        result = true
      }
      return result
    }
  },
  methods: {
    async saveWithUpdate () {
      await this.saveDetail(false)
      this.updatedServiceData()
      this.updatedTab()
      return true
    },
    updatedServiceData () {
      this.$store.commit('serviceData/updateOption', {
        categoryChain: 'users.clients',
        data: { id: this.detail.id, value: this.detail.username }
      })
    },
    updatedTab () {
      for (let compilation in this.detail.compilations) {
        let tabIndex = this.$store.getters['tab/indexById']('advert', this.detail.compilations[compilation].id)
        if (tabIndex > -1) {
          this.$store.commit('tab/update', {
            categoryName: 'advert',
            tabIndex,
            data: {
              title: this.detail.compilations[compilation].name,
              requestParams: {
                filters: this.detail.compilations[compilation].compilation,
                options: {}
              }
            }
          })
        }
      }
    },
    async hasErrors () {
      let canSave = false
      this.$v.$touch()
      await this.checkPending()
      if (this.$v.$anyError) {
        canSave = 'Введите корректные данные'
      } else {
        this.$v.$reset()
      }
      return canSave
    },
    handleDelete () {
      this.confirmDelete = false
      this.$emit('delete')
    }
  }
}
</script>

<style scoped>
  .on-air {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #999999;
  }
</style>
