<template>
  <v-card
    outlined
    class="mt-1 pa-1"
  >
    <div class="d-flex align-md-center justify-space-between">
      <v-card-title>Блок информации</v-card-title>
      <!--      <div
              v-if="searchReason && searchReason.length"
              class="caption"
            >{{ $t('user.searchReason') }}: <b>{{ searchReason[0] ? searchReason[0].text : '' }}</b>
            </div>-->
    </div>
    <v-card-text>
      <v-row class="justify-space-around pl-3 pr-3">
        <v-col
          cols="5"
          class="pt-0 pb-0"
        >
          <v-text-field
            :value="whatsapp"
            @input="$emit('update:whatsapp', $event)"
            outlined
            dense
            :label="$t('user.whatsApp')"
          ></v-text-field>
        </v-col>
        <v-col
          cols="2"
          class="pt-0 pb-0"
        >
          <DatePicker
            :value="birthDate"
            @input="$emit('update:birthDate', $event)"
            :label="$t('user.birthDate')"
            :prepend-icon="''"
            :outlined="true"
            :dense="true"
          />
        </v-col>
        <v-col
          cols="5"
          class="pt-0 pb-0"
        >
          <v-text-field
            :value="address"
            @input="$emit('update:address', $event)"
            outlined
            dense
            :label="$t('user.address')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="justify-space-around pl-3 pr-3">
        <v-col
          cols="3"
          class="pt-0 pb-0"
        >
          <v-select
            :value="paymentMethod"
            @input="$emit('update:paymentMethod', $event)"
            :items="paymentMethodItems"
            dense
            item-value="id"
            item-text="value"
            outlined
            :label="$t('user.paymentMethod')"
            class="text-left"
          ></v-select>
        </v-col>
        <v-col
          cols="9"
          class="pt-0 pb-0"
        >
          <v-text-field
            :value="description"
            @input="$emit('update:description', $event)"
            outlined
            dense
            label="Комментарий к способу оплаты"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="justify-space-around pl-3 pr-3">
        <v-col cols="6">
          <DateTimePicker
            :value="delayUntil"
            @input="$emit('update:delayUntil', $event)"
            :label="$t('user.delayUntil')"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            :value="delayComment"
            @input="$emit('update:delayComment', $event)"
            outlined
            :label="$t('user.delayComment')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import formatters from '@/components/mixins/formatters'
import DatePicker from '@/components/views/admin/common/DatePicker'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import BaseBtn from '@/components/views/admin/common/BaseButtonBar/BaseBtn'
import DateTimePicker from '@/components/views/admin/common/DateTimePicker'

export default {
  name: 'ClientData',
  components: { DateTimePicker, BaseBtn, DatePicker },
  mixins: [serviceDataComputeds, formatters],
  props: {
    whatsapp: String,
    description: String,
    searchReason: Array,
    address: String,
    source: Number,
    paymentMethod: Number,
    birthDate: Number,
    delayUntil: Number,
    delayComment: String
  }
}
</script>

<style scoped>

</style>
