<template>
  <v-card
    outlined
    class="pa-1"
  >
    <div class="d-flex align-md-center">
      <v-card-title>Данные о клиенте</v-card-title>
      <v-spacer></v-spacer>
      <div
        v-if="delayUntil"
        class="caption pa-2 mr-6 delay-until"
      >{{ $t('advert.delay_until') }}: <b>{{ timestampToDate(delayUntil) }}</b>
      </div>
      <div class="pr-4 pb-1">
        <div
          v-if="createdAt"
          class="caption"
        >{{ $t('base.created_at') }}: <b>{{ formatTodayDate(createdAt) }}</b>
        </div>
        <div
          v-if="updatedAt"
          class="caption"
        >{{ $t('base.updated_at') }}: <b>{{ formatTodayDate(updatedAt) }}</b>
        </div>
      </div>
    </div>
    <v-row>
      <v-col
        cols="12"
        class="pt-0 pb-0"
      >
        <div class="d-flex justify-space-around pt-1">
          <v-select
            :value="impression"
            @input="$emit('update:impression', $event)"
            :items="impressionItems"
            outlined
            item-value="id"
            item-text="value"
            dense
            :label="$t('user.impression')"
            class="text-left select-width"
          ></v-select>
          <v-select
            :value="successRate"
            @input="$emit('update:successRate', $event)"
            :items="successRateItems"
            outlined
            item-value="id"
            item-text="value"
            dense
            :label="$t('user.successRate')"
            class="text-left select-width"
          ></v-select>
          <v-select
            :value="dealStep"
            @input="$emit('update:dealStep', $event)"
            :items="dealStepItems"
            outlined
            item-value="id"
            item-text="value"
            dense
            :label="$t('user.dealStep')"
            class="text-left select-width"
          ></v-select>
          <v-select
            :value="value"
            @input="changeEscortAgent"
            :items="agentsWithEmpty"
            outlined
            dense
            :disabled="canEdit"
            item-value="id"
            item-text="value"
            label="Ответственный агент"
            class="text-left select-width"
          ></v-select>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [serviceDataComputeds, formatters],
  name: 'ClientSelects',
  props: {
    impression: Number,
    successRate: Number,
    dealStep: Number,
    delayUntil: Number,
    createdAt: Number,
    updatedAt: Number,
    value: Number,
    canEdit: Boolean,
    escortAgent: Array
  },
  data () {
    return {
      isLoading: false,
      agentList: []
    }
  },
  computed: {
    agentsWithEmpty () {
      let agentList = this.$store.getters['serviceData/optionsList']('users.agents')
      let profile = this.$store.getters['auth/getProfile']
      if (profile && profile.role === 'agent' && this.canEdit === false) {
        agentList = agentList.filter(item => !item.id || item.id === profile.id)
      }
      return agentList
    }
  },
  methods: {
    changeEscortAgent (data) {
      let escortHistory = this.escortAgent
      if (!Array.isArray(escortHistory)) {
        escortHistory = []
      }
      if (data) {
        escortHistory.push(`${(new Date()).toLocaleString('ru-RU')}: Клиента ведет ${this.formatValue('users.agents', data)}. Его назначил ${this.$store.getters['auth/getName']}`)
      } else {
        escortHistory.push(`${(new Date()).toLocaleString('ru-RU')}: Клиента никто не ведет. Решение принял ${this.$store.getters['auth/getName']}`)
      }
      this.$emit('input', data)
      this.$emit('update:escortAgent', escortHistory)
    }
  }
}
</script>

<style scoped>
.select-width {
  max-width: 250px;
}
</style>
