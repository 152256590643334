<template>
  <v-card
    outlined
    class="mt-1 pa-1"
  >
    <v-row>
      <v-col cols="4">
        <v-card
          outlined
          elevation="0"
          min-height="200"
          class="mt-1 pa-1"
        >
          <div class="d-flex align-md-center justify-space-between">
            <v-card-title>Вкладки<br> (пожелания)</v-card-title>
            <v-btn
              @click="handleAddCompilation"
              small
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pt-1 scrollIfSoBig">
            <div
              :class="'ma-0 ' + buttonColor(item)"
              v-for="(item, index) in localValue"
              :key="`${item.id}${index}${localValue.length}`"
            >
              <v-menu
                :close-on-content-click="false"
                :close-on-click="false"
                ref="edit"
              >
                <template v-slot:activator="{ on }">
                  <div class="pa-0 d-flex max">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <div class="d-flex align-center">
                          <span v-if="item.unviewedAdvertCount" class="badge-custom"> {{ item.unviewedAdvertCount }} </span>
                          <p
                            class="ml-2 mt-4 pa-0 "
                            v-on="on"
                          >
                            {{ item.name ? item.name : 'Вкладка' | truncate(10, '...') }}
                          </p>
                        </div>
                      </template>
                      {{ item.name ? item.name : 'Вкладка' }}
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <div class="d-flex justify-space-between mt-3">
                      <v-btn
                        v-on="on"
                        class="pa-0"
                        small
                        color="black"
                        elevation="0"
                        icon
                      >
                        <v-icon small>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="item.id === null"
                        class="pa-0 mr-2"
                        small
                        elevation="0"
                        icon
                        color="black"
                        @click="handlePushLink(index)"
                      >
                        <v-icon small>
                          mdi-link
                        </v-icon>
                      </v-btn>
                      <v-btn
                        class="pa-0 mr-2"
                        small
                        elevation="0"
                        color="black"
                        @click="handleRemove(index)"
                        icon
                      >
                        <v-icon small>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
                <Compilation
                  :item="item"
                  @close="handleInternalClose('edit', index)"
                />
              </v-menu>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <ChatLog
          :card-title="'История ведения клиента'"
          :attribute="'client_history'"
          v-model="clientHistory"
        />
      </v-col>
      <v-col cols="4">
        <ChatLog
          :card-title="'История параметров поиска'"
          :attribute="'search_reason'"
          v-model="searchReason"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import Compilation from '@/components/views/admin/clients/Detail/ClientWorkspace/Compilation'
import CompilationStructure from '@/components/structures/compilationStructure'
import { file, list } from '@/config'
import ChatLog from '@/components/views/admin/common/ChatLog'

export default {
  components: { Compilation, ChatLog },
  name: 'ClientWorkspace',
  props: {
    compilations: Array,
    clientHistory: Array,
    searchReason: Array,
    id: Number
  },
  data () {
    return {
      localValue: this.compilations,
      usersIds: {
        client_id: this.id,
        agent_id: null
      }
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text && text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    }
  },
  watch: {
    compilations: {
      handler () {
        this.localValue = this.compilations
      },
      deep: true
    }
  },
  methods: {
    handleAddCompilation () {
      let profile = this.$store.getters['auth/getProfile']
      if (profile) {
        this.usersIds.agent_id = profile.id
      }
      this.localValue.push(new CompilationStructure(this.usersIds))
    },
    handleRemove (index) {
      this.localValue.splice(index, 1)
    },
    handlePushLink (index) {
      let tabIndex = this.$store.getters['tab/indexById']('advert', this.localValue[index].id)
      if (tabIndex > -1) {
        this.$store.commit('tab/update', {
          categoryName: 'advert',
          tabIndex,
          data: {
            title: this.localValue[index].name,
            requestParams: {
              filters: this.localValue[index].compilation,
              options: {}
            }
          }
        })
        this.$store.commit('tab/setActiveTab', { categoryName: 'advert', tabIndex })
      } else {
        this.add('advert', this.localValue[index].type, {
          title: this.localValue[index].name,
          id: this.localValue[index].id,
          client_id: this.localValue[index].client_id,
          agent_id: this.localValue[index].agent_id,
          requestParams: {
            filters: this.localValue[index].compilation,
            options: {}
          }
        })
      }
      this.$router.push({ name: 'advert' })
    },
    buttonColor (item) {
      let resultClass = ''
      switch (item.type) {
        case file.value:
          resultClass = ''
          break
        case list.value:
          resultClass = 'blue lighten-5'
      }
      return resultClass
    },
    add (categoryName, type, data) {
      this.$store.commit('tab/add', { categoryName, type, data })
    },
    handleInternalClose (name, index = null) {
      let ref = this.$refs[name]
      if (index !== null) {
        ref = ref[index]
      }
      ref.isActive = false
    }
  }
}
</script>

<style scoped>
.size {
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
  padding: 5px; /* Поля */
  text-overflow: ellipsis !important; /* Многоточие */
}
.scrollIfSoBig {
  max-height: 45vh;
  overflow: auto;
}
.badge-custom {
  background-color: red;
  color: #ffffff;
  font-size: 15px;
  padding: 3px 11px;
  border-radius: 50%;
}
</style>
