<template>
  <v-datetime-picker
    ref="dateTimePicker"
    :label="label"
    v-model="activeDate"
    date-format="dd-MM-yyyy"
    clear-text="Очистить"
    ok-text="Сохранить"
    :timePickerProps="timePickerProps"
  >
    <template #dateIcon>
      <v-icon>mdi-calendar-range</v-icon>
    </template>
    <template #timeIcon>
      <v-icon>mdi-clock-edit-outline</v-icon>
    </template>
  </v-datetime-picker>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import { cleanClone } from '@/components/utils/common'
/* eslint-disable */
export default {
  name: 'DateTimePicker',
  mixins: [formatters],
  props: {
    value: Number,
    label: String
  },
  data () {
    return {
      timePickerProps: {
        format: '24hr'
      },
      newValue: cleanClone(this.value),
      isSaving: false,
      key: 0
    }
  },
  computed: {
    activeDate: {
      get () {
        let key = this.key
        key--
        if (!this.newValue) return null
        return new Date(this.newValue * 1000)
      },
      set (val) {
        if (!val) return this.$emit('input', null)
        this.$emit('input', this.dateToTimestamp(new Date(val).toISOString()))
        this.isSaving = true
      }
    }
  },
  watch: {
    value () {
      this.newValue = cleanClone(this.value)
    }
  },
  async mounted () {
    // эмулируем clickOutside
    return this.$nextTick(() => {
      return this.$watch(() => this.$refs.dateTimePicker.display, (newVal, oldVal) => {
        if (newVal === false && oldVal === true) {
          if (this.isSaving) {
            this.isSaving = false
          } else if (!this.value) { //note: срабатывает при сбросе и старом пустом значении
            this.$refs.dateTimePicker.clearHandler()
          } else {
            this.newValue = cleanClone(this.value)
            this.key++
          }
        }
      })
    })
  }
}
</script>

<style scoped>

</style>
