import AdvertFilterList from '@/components/structures/filters/advertFilterList'
import { file } from '@/config'

class CompilationStructure {
  constructor (props = null) {
    this.id = null
    this.unviewedAdvertCount = null
    this.client_id = props ? props.client_id : null
    this.agent_id = props ? props.agent_id : null
    this.name = null
    this.compilation = new AdvertFilterList()
    this.type = file.value
  }
}

export default CompilationStructure
